import React from "react"
import PropTypes from "prop-types"

// Material UI
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"

// Components
import Button from "@components/Button"
import Link from "@components/Link"
import Typography from "@components/Typography"

const styles = (theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(6),
    },
    button: {
        border: "4px solid currentColor",
        borderRadius: 0,
        height: "auto",
        padding: theme.spacing(2, 5),
    },
    title: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    tagline: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
})

function LandingQuestions(props) {
    const { classes } = props

    return (
        <Container id="faq" className={classes.root} component="section" maxWidth={"md"}>
            <Typography variant="h4" align="center" className={classes.title}>
                Hungry for <span style={{ color: "#ff3366" }}> feedback</span>?
            </Typography>
            <Typography
                className={classes.tagline}
                component="h2"
                variant="h5"
                align="center"
                noWrap
            >
                we are here to serve...
            </Typography>
            <Link component={Button} href="/faq" className={classes.button}>
                <Typography variant="h4" component="span">
                    FAQ/BBQ
                </Typography>
            </Link>
        </Container>
    )
}

LandingQuestions.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LandingQuestions)
