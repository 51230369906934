import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

// Libs
import { UserContext } from "@lib/context/UserContext"

// Material UI
import { useTheme, withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Components
import Button from "@components/Button"
import Link from "@components/Link"
import { DialogTitle, DialogContent, DialogActions } from "@components/Dialog"
import Typography from "@components/Typography"

// Icons
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"
import PersonIcon from "@material-ui/icons/Person"

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(4),
        },
    },
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(0),
        textAlign: "center",
    },
    subtitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(0),
        textAlign: "justify",
    },
    button: {
        boxShadow:
            "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "180px",
        fontSize: "18px",
    },
    dialogPaper: {
        height: "70vh",
    },
})

function LandingMessage(props) {
    const { classes } = props

    const { status } = useContext(UserContext)

    const authorized = status === "complete"

    const message = authorized ? "CHECK USER SKIP/S" : "...OR TRY FOR YOURSELF"
    const link = authorized ? "/s" : "/beta"

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Container maxWidth={"sm"} component="section">
            <Grid
                container
                justifyContent={"center"}
                alignItems={"flex-start"}
                spacing={1}
                className={classes.root}
            >
                <Grid item container justifyContent="center" xs={12} md={6}>
                    <Grid item>
                        <Button
                            startIcon={
                                <PlayCircleFilledIcon style={{ fontSize: "30px" }} />
                            }
                            color="secondary"
                            variant="contained"
                            onClick={handleClickOpen}
                            className={classes.button}
                        >
                            {"Watch how... 🤔"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>
                        <Button
                            endIcon={<PersonIcon style={{ fontSize: "30px" }} />}
                            color="secondary"
                            variant="contained"
                            component={Link}
                            href={link}
                            className={classes.button}
                        >
                            {message}
                        </Button>
                    </Grid>
                    <Grid item>
                        {!authorized && (
                            <Typography color="primary" align="center" variant="caption">
                                No Email or Credit Card Required
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item></Grid>
            </Grid>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="explainer-dialog-title"
                open={open}
            >
                <DialogTitle id="explainer-dialog-title" onClose={handleClose}>
                    S-K-I-P Homemade Walk Through
                </DialogTitle>
                <DialogContent className={classes.dialogPaper}>
                    <iframe
                        width="100%"
                        height="98%"
                        src="https://www.youtube-nocookie.com/embed/O3kwi54rSG8?controls=1&rel=0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                    ></iframe>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="contained"
                        component={Link}
                        href={link}
                        className={classes.button}
                    >
                        {message}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

LandingMessage.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LandingMessage)
