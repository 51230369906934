import React from "react"

// Views
import AppPanel from "@views/AppPanel"
import Landing from "@views/Landing"

// Components
import Metatags from "@components/Metatags"

// MaterialUI
import { makeStyles } from "@material-ui/core/styles"

// import { SKIP_BACKGROUND_SVG } from "@data/images"

// Lib
// import { getPostCollectionData } from "@lib/data/postData"

// export async function getServerSideProps(context) {
//     const posts = await getPostCollectionData({
//         published: true,
//         type: "demo",
//         limit: 4,
//         json: true,
//     })
//     return {
//         props: { posts }, // will be passed to the page component as props
//     }
// }

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundImage: `url("${SKIP_BACKGROUND_SVG}")`,
        // backgroundPosition: "inherit",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
    },
}))

export default function Home(props) {
    const classes = useStyles()
    return (
        <AppPanel classes={classes}>
            <Metatags />
            {/* <Landing posts={props.posts} /> */}
            <Landing />
        </AppPanel>
    )
}
