import React, { useContext } from "react"
import PropTypes from "prop-types"

// Libs
import { UserContext } from "@lib/context/UserContext"

// Material UI
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useTheme, withStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Tooltip from "@material-ui/core/Tooltip"

// Components
import Typography from "@components/Typography"
import Link from "@components/Link"
import { handleJumpToAnchor } from "@components/JumpToAnchorText"

// Views
import LandingMessage from "@views/LandingMessage"

const styles = (theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(10),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(5),
        },
        marginBottom: theme.spacing(11),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    title: {
        textTransform: "none",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0),
        },
        marginBottom: theme.spacing(5),
    },
    toptitle: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    typist: {
        display: "inline",
        //color: "#ff3366",
    },
    logo: {
        width: "100%",
    },
    desktopOnly: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    mobileOnly: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        },
    },
})

function LandingHero(props) {
    const { classes } = props

    const { status } = useContext(UserContext)

    const authorized = status === "complete"
    const link =
        "https://twitter.com/intent/tweet?text=Find%20out%20what%27s%20worth%20your%20time...&hashtags=skiptothegoodbit&url=skiptothegoodbit.com"

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    })
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"), {
        defaultMatches: true,
    })
    const isMedium = useMediaQuery(theme.breakpoints.only("md"), {
        defaultMatches: true,
    })

    return (
        <Container className={classes.root} component="section">
            <Grid
                container
                spacing={isSmall ? 5 : 0}
                justifyContent={isSmall ? "center" : "flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={10} className={classes.mobileOnly}>
                    <img
                        onClick={(event) =>
                            handleJumpToAnchor(event, "#how-it-works", { block: "start" })
                        }
                        src={"/content-hero-line.svg"}
                        alt="content-line"
                        className={classes.logo}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={7}>
                    <Typography
                        component="h1"
                        variant={
                            isMobile ? "h4" : isSmall ? "h2" : isMedium ? "h2" : "h1"
                        }
                        align="center"
                        className={classes.title}
                    >
                        <span style={{ color: "#ff3366" }}>{"Find"}</span> out for
                        yourself <br />
                        {"what's "}
                        worth <span style={{ color: "#ff3366" }}>{"your time..."}</span>
                    </Typography>
                    <Container component="section" maxWidth="sm">
                        <Typography
                            component="h2"
                            variant={isMobile ? "body1" : "h5"}
                            align="center"
                            className={classes.subtitle}
                        >
                            Easily <b>review</b>, <b>annotate</b>, and <b>share</b> the{" "}
                            <span style={{ color: "#ff3366" }}>{'"GOOD BITS"'}</span>
                            <br />
                            from <b>videos</b>, <b>podcasts</b>, <b>lectures</b>,{" "}
                            <b>drafts</b>, <b>edits</b>,
                            <br />
                            or your own <b>personal recordings</b>...
                            <br />
                            <Link href={link}>
                                <Tooltip
                                    title={"❤️ #skiptothegoodbit"}
                                    arrow
                                    placement="right"
                                >
                                    <span style={{ color: "#ff3366" }}>
                                        {"...and save hours of your time! "}
                                    </span>
                                </Tooltip>
                            </Link>
                            {/* <br />
                            <br />
                            try{" "}
                            <a
                                href="https://youtube.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                YouTube
                            </a>
                            ,{" "}
                            <a
                                href="https://vimeo.com/watch"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Vimeo
                            </a>
                            ,{" "}
                            <a
                                href="https://soundcloud.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                SoundCloud
                            </a>
                            ... */}
                        </Typography>
                    </Container>
                    <LandingMessage />
                </Grid>
                <Grid item sm={4} md={4} className={classes.desktopOnly}>
                    <img
                        onClick={(event) => handleJumpToAnchor(event, "#how-it-works")}
                        src={"/content-hero-all.svg"}
                        alt="content"
                        className={classes.logo}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

LandingHero.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LandingHero)
