import React from "react"

// Views
import LandingHero from "@views/LandingHero"
import LandingHowItWorks from "@views/LandingHowItWorks"
import LandingQuestions from "@views/LandingQuestions"
import LandingUsedBy from "@views/LandingUsedBy"
import LandingPricing from "@views/LandingPricing"

export default function Landing(props) {
    return (
        <>
            <LandingHero />
            <LandingHowItWorks />
            <LandingUsedBy />
            <LandingPricing />
            <LandingQuestions />
        </>
    )
}
