import React from "react"
import PropTypes from "prop-types"

// Components
import Typography from "@components/Typography"
import { handleJumpToAnchor } from "@components/JumpToAnchorText"

// Material UI
import { useTheme, withStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Icon from "@material-ui/core/Icon"

// Icons

const styles = (theme) => ({
    root: {
        display: "flex",
        // border: "1px solid var(--color-gray)",
        // borderRadius: "8px",
        overflow: "hidden",
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(0),
        },
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    item: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(0, 0),
    },
    title: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(5),
        cursor: "pointer",
    },
    tagline: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    caption: {
        fontSize: 20,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        fontSize: 200,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    logo: {
        pointerEvents: "none",
        width: "100%",
    },
})

function LandingHowItWorks(props) {
    const { classes } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
        defaultMatches: true,
    })

    return (
        <section id="how-it-works" className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Typography
                    onClick={(event) =>
                        handleJumpToAnchor(event, "#how-it-works", { block: "start" })
                    }
                    variant="h5"
                    marked="none"
                    className={classes.title}
                    component="h2"
                    noWrap
                >
                    <b>
                        it <span style={{ color: "#ff3366" }}>works</span> in three simple
                        steps... ⬇️
                    </b>
                </Typography>
                <div>
                    <Grid container spacing={6} justifyContent={"space-around"}>
                        <Grid item xs={12} md={4}>
                            <div className={classes.item}>
                                {/* <div className={classes.number}>Paste content link</div> */}
                                {/* <SubscriptionsIcon alt="watch" className={classes.icon} /> */}
                                <Icon className={classes.icon}>
                                    <img
                                        src={"/content.svg"}
                                        alt="content"
                                        className={classes.logo}
                                    />
                                </Icon>
                                <Typography
                                    className={classes.caption}
                                    variant="h6"
                                    align="center"
                                    noWrap
                                >
                                    Paste content link
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    align="center"
                                    noWrap
                                >
                                    {"From file, YouTube, Podcast..."}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.item}>
                                {/* <div className={classes.number}>{"Mark what's useful"}</div> */}
                                {/* <BookmarksIcon alt="mark" className={classes.icon} /> */}
                                <Icon className={classes.icon}>
                                    <img
                                        src={"/marks.svg"}
                                        alt="marks"
                                        className={classes.logo}
                                    />
                                </Icon>
                                <Typography
                                    className={classes.caption}
                                    variant="h6"
                                    align="center"
                                    noWrap
                                >
                                    {"Mark the good bits"}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    align="center"
                                    noWrap
                                >
                                    {"Notes, likes, dislikes, flag, funny..."}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.item}>
                                {/* <div className={classes.number}>Share or keep...</div> */}
                                <Icon className={classes.icon}>
                                    <img
                                        src={"/skips.svg"}
                                        alt="skips"
                                        className={classes.logo}
                                    />
                                </Icon>
                                <Typography
                                    className={classes.caption}
                                    variant="h6"
                                    align="center"
                                    noWrap
                                >
                                    {"Share or save them..."}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    align="center"
                                    noWrap
                                >
                                    {"Slack / Twitter, Email newsletter..."}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    )
}

LandingHowItWorks.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LandingHowItWorks)
