/* eslint-disable react/jsx-key */
import React from "react"
import Flickity from "react-flickity-component"

import "flickity/css/flickity.css"

// Material UI
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"

// Components
import Typography from "@components/Typography"
import { USED_BY_LIST } from "@data/images"

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(25),
        marginBottom: theme.spacing(25),
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    tagline: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(4),
    },
    carouselImage: {
        display: "block",
        height: "150px",
        /* set min-width,
         allow images to determine cell width */
        minWidth: "150px",
        maxWidth: "100%",

        marginLeft: "100px",
        marginRight: "100px",

        /* vertically center */
        top: "50%",
        transform: "translateY(-50%)",
    },
    flickityButton: {
        background: "transparent",
    },
})

function LandingUsedBy(props) {
    const { classes } = props

    const flickityOptions = {
        lazyLoad: 2,
        autoPlay: true,
        freeScroll: true,
        dragable: true,
        wrapAround: true,
        // disable previous & next buttons and dots
        prevNextButtons: false,
        pageDots: true,
    }

    return (
        <Container id="used-by" maxWidth="sm" className={classes.root}>
            <Typography
                variant="h3"
                marked="none"
                className={classes.title}
                component="h2"
                align="center"
                noWrap
            >
                Who else
                <span style={{ color: "#ff3366" }}> uses </span>
                it?
            </Typography>
            <Typography
                className={classes.tagline}
                component="h2"
                variant="h5"
                align="center"
                noWrap
            >
                to name a few...
            </Typography>
            <Flickity options={flickityOptions} static>
                {USED_BY_LIST.map((source, i) => (
                    <img
                        key={`usedby${i}`}
                        className={classes.carouselImage}
                        src={source}
                    />
                ))}
            </Flickity>
        </Container>
    )
}

export default withStyles(styles)(LandingUsedBy)
